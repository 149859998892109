<template>
  <div id="preOrders-list">
    <!-- app drawer -->
    <pre-list-add-new v-model="isAddNewPreOrderSidebarActive" @refetch-data="fetchPreOrders"></pre-list-add-new>

    <!-- preOrders total card -->
    <v-row class="mb-5">
      <v-col v-for="total in preOrdersTotalLocal" :key="total.total" cols="12" sm="6" md="3">
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title> Pre-Orders </v-card-title>
      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <!-- <v-text-field
          v-model="searchQuery"
          placeholder="Search"
          outlined
          hide-details
          dense
          class="preOrders-search me-3 mb-4"
        >
        </v-text-field> -->

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click.stop="isAddNewPreOrderSidebarActive = !isAddNewPreOrderSidebarActive"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Add New Pre-Order</span>
          </v-btn>
          <!-- <v-btn color="secondary" outlined class="mb-4">
            <v-icon size="17" class="me-1">
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Export</span>
          </v-btn> -->
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="preOrdersListTable"
        :options.sync="options"
        :server-items-length="totalPreOrdersListTable"
        :loading="loading"
        @refetch-data="fetchPreOrders"
      >
        <!-- name EN -->
        <template #[`item.name_en`]="{ item }">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'apps-pre-view', params: { id: item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.name_en }}
              </router-link>
            </div>
          </div>
        </template>

        <!-- name AR -->
        <template #[`item.name_ar`]="{ item }">
          <div class="d-flex align-center">
            <span class="text-capitalize">{{ item.name_ar }}</span>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :to="{ name: 'apps-pre-view', params: { id: item.id } }">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item link @click="handleDelete(item.id)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiEye,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
} from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import Swal from 'sweetalert2'
import preordersStoreModule from '../preordersStoreModule'
import PreListAddNew from './PreListAddNew.vue'
import usePreList from './usePreList'

export default {
  components: {
    PreListAddNew,
  },
  setup(props, { emit }) {
    const PREORDERS_APP_STORE_MODULE_NAME = 'app-preorders'

    // Register module
    if (!store.hasModule(PREORDERS_APP_STORE_MODULE_NAME)) {
      store.registerModule(PREORDERS_APP_STORE_MODULE_NAME, preordersStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PREORDERS_APP_STORE_MODULE_NAME)) store.unregisterModule(PREORDERS_APP_STORE_MODULE_NAME)
    })
    const {
      preOrdersListTable,
      tableColumns,
      searchQuery,
      totalPreOrdersListTable,
      loading,
      options,
      preOrdersTotalLocal,
      selectedRows,

      fetchPreOrders,
    } = usePreList()

    const isAddNewPreOrderSidebarActive = ref(false)

    const planOptions = [
      { title: 'Pre-Order Name EN', value: 'name_en' },
      { title: 'Pre-Order Name AR', value: 'name_ar' },
    ]

    const handleDelete = id => {
      Swal.fire({
        title: 'Are you sure you want to delete this item?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          store
            .dispatch('app-preorders/deletePreOrder', id)
            .then(() => {
              preOrdersListTable.value = preOrdersListTable.value.filter(item => item.id !== id)
              Swal.fire('Deleted!', 'entry has been deleted.', 'success')
            })
            .catch(error => {
              let textValue = null
              if (_.isObject(error?.response?.data.message)) {
                textValue = `${Object.values(error.response.data.message).join('\n')}`
              } else if (error?.response?.data.message) {
                textValue = error.response.data.message
              } else {
                textValue = 'something Error '
              }
              Swal.fire({
                icon: 'error',
                title: `Error`,
                text: textValue,
              })
            })
        }
      })
    }

    return {
      preOrdersListTable,
      tableColumns,
      searchQuery,
      totalPreOrdersListTable,
      loading,
      options,
      preOrdersTotalLocal,
      isAddNewPreOrderSidebarActive,
      selectedRows,
      planOptions,
      avatarText,
      fetchPreOrders,
      handleDelete,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiEye,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
