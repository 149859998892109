<template>
  <v-navigation-drawer
    :value="isAddNewPreOrderSidebarActive"
    :permanent="isAddNewPreOrderSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 450 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-preorder-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New Pre-Order</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-preorder-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="onSubmit"
        >
          <v-text-field
            v-model="preorderData.name_en"
            outlined
            dense
            :rules="[validators.required]"
            label="Name (En)"
            placeholder="Italian"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-card-text v-if="message.name_en" style="color: #ff3342; white-space: pre-wrap">
            {{ message.name_en[0] }}
          </v-card-text>

          <v-text-field
            v-model="preorderData.name_ar"
            :rules="[validators.required]"
            outlined
            dense
            label="Name (Ar)"
            placeholder="إيطالي"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-card-text v-if="message.name_ar" style="color: #ff3342; white-space: pre-wrap">
            {{ message.name_ar[0] }}
          </v-card-text>

          <v-file-input
            v-model="preorderData.image"
            accept="image/png, image/jpeg, image/bmp"
            placeholder="Choose an image"
            outlined
            dense
            label="Upload Image"
            hide-details="auto"
            class="mb-6"
            prepend-icon=""
            hint="Desired dimensions: min-width: 126px; max-width: 252px; min-height: 95px; max-height: 189px; max-file-size: 512kb"
            :rules="[validators.imageValidator]"
            persistent-hint
          >
          </v-file-input>

          <v-card-text v-if="message.image" style="color: #ff3342; white-space: pre-wrap">
            {{ message.image[0] }}
          </v-card-text>

          <v-btn
            color="primary"
            class="me-3"
            type="submit"
          >
            Add
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="resetPreOrderData"
          >
            Cancel
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { imageValidator, integerValidator, required } from '@core/utils/validation'

import { mdiClipboardFileOutline, mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Swal from 'sweetalert2'

export default {
  model: {
    prop: 'isAddNewPreOrderSidebarActive',
    event: 'update:is-add-new-preorder-sidebar-active',
  },
  props: {
    isAddNewPreOrderSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const blankPreorderData = {
      name_en: '',
      name_ar: '',
      image: [],
      add_ones: false,
      used_in_all_meals: false,
    }

    const valid = ref(false)
    const form = ref(null)
    const message = ref('')

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const preorderData = ref(JSON.parse(JSON.stringify(blankPreorderData)))
    const resetPreOrderData = () => {
      preorderData.value = JSON.parse(JSON.stringify(blankPreorderData))
      resetForm()
      emit('update:is-add-new-preorder-sidebar-active', false)
    }

    const onSubmit = () => {
      if (valid.value) {
        store
          .dispatch('app-preorders/addPreOrder', preorderData.value)
          .then(() => {
            emit('refetch-data')
            resetPreOrderData()
            message.value = ''
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Pre-orders has been added successfully',
              showConfirmButton: true,
            })
          })
          .catch(error => {
            message.value = error.response.data.message
          })
      } else {
        validate()
      }
    }

    return {
      message,
      resetPreOrderData,
      form,
      onSubmit,
      countries,
      preorderData,
      valid,
      validate,

      // validation
      validators: {
        integerValidator,
        required,
        imageValidator,
      },
      icons: {
        mdiClose,
        mdiClipboardFileOutline,
      },
    }
  },
}
</script>
