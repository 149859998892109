import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function usePreOrdersList() {
  const preOrdersListTable = ref([])

  const tableColumns = [
    { text: 'Name (En)', value: 'name_en' },
    { text: 'Name (Ar)', value: 'name_ar' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const totalPreOrdersListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const preOrdersTotalLocal = ref([])
  const selectedRows = ref([])

  // fetch data
  const fetchPreOrders = () => {
    store
      .dispatch('app-preorders/fetchPreOrders')
      .then(response => {
        const { filteredData, total, preOrdersTotal } = response.data.data
        preOrdersListTable.value = filteredData
        totalPreOrdersListTable.value = total
        preOrdersTotalLocal.value = preOrdersTotal

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchPreOrders()
  })

  return {
    preOrdersListTable,
    tableColumns,
    totalPreOrdersListTable,
    searchQuery,
    preOrdersTotalLocal,
    loading,
    options,
    selectedRows,
    fetchPreOrders,
  }
}
